import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Wallet from './components/Wallet';
import Swap from './components/Swap';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  return (
    <Wallet>
      <Router>
        <div className="App">
          <header className="header">
            <div className="brand-logo">
              <img src="/logo200.png" alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
              <span>IMEME-DEX</span>
            </div>
            <div className="wallet-button">
              <WalletMultiButton />
            </div>
          </header>
          <main>
            <Routes>
              <Route path="/" element={<Swap />} /> {/* تعيين Swap كصفحة رئيسية */}
            </Routes>
          </main>
          <ToastContainer position="top-right" autoClose={5000} />
        </div>
      </Router>
    </Wallet>
  );
}

export default App;
