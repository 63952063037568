import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { VersionedTransaction, Connection, clusterApiUrl } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { Buffer } from 'buffer';
import { tokenList } from './tokenList.js';
import Select from 'react-select';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const connection = new Connection(clusterApiUrl('mainnet-beta'));

function Swap() {
    const { publicKey, sendTransaction } = useWallet();
    const [fromCurrency, setFromCurrency] = useState('SOL');
    const [toCurrency, setToCurrency] = useState('USDC');
    const [amount, setAmount] = useState('');
    const [price, setPrice] = useState(null);
    const [convertedAmount, setConvertedAmount] = useState('');
    const [walletBalance, setWalletBalance] = useState(0);
    const [customTokens, setCustomTokens] = useState([]);
    const [slippageBps] = useState(50);

    const currencies = useMemo(() => {
        const popularTokens = tokenList.map(token => ({
            symbol: token.symbol,
            mint: token.address,
            logoURI: token.logoURI
        }));
        return [...popularTokens, ...customTokens];
    }, [customTokens]);

    const mintAddresses = useMemo(() => {
        const mints = {};
        currencies.forEach(token => {
            mints[token.symbol] = token.mint;
        });
        return mints;
    }, [currencies]);

    const conversionFactors = useMemo(() => ({
        SOL: 1e9,
        USDC: 1e6,
    }), []);

    const convertAmount = useCallback((amount, currency) => {
        const factor = conversionFactors[currency] || 1;
        return amount * factor;
    }, [conversionFactors]);

    const fetchWalletBalance = useCallback(async () => {
        if (!publicKey) {
            return;
        }

        try {
            const balance = await connection.getBalance(publicKey);
            setWalletBalance(balance / 1e9);  // تحويل الرصيد من lamports إلى SOL
        } catch (error) {
            console.error('Error fetching wallet balance:', error);
        }
    }, [publicKey]);

    useEffect(() => {
        fetchWalletBalance();

        const storedTokens = JSON.parse(localStorage.getItem('customTokens')) || [];
        setCustomTokens(storedTokens);
    }, [fetchWalletBalance]);

    useEffect(() => {
        const fetchPrice = async () => {
            try {
                const response = await axios.get(
                    `https://price.jup.ag/v6/price?ids=${mintAddresses[fromCurrency]}&vsToken=${mintAddresses[toCurrency]}`
                );

                console.log("Price API Response:", response.data);

                const fetchedPrice = response.data.data[mintAddresses[fromCurrency]]?.price;
                if (!fetchedPrice) {
                    console.error(`No price found for ${fromCurrency} to ${toCurrency}`);
                    setPrice(null);
                    toast.warn(`Price not available for ${fromCurrency} to ${toCurrency}`);
                } else {
                    setPrice(fetchedPrice);
                    if (fetchedPrice && amount) {
                        setConvertedAmount((amount * fetchedPrice).toFixed(8));
                    }
                }
            } catch (error) {
                console.error('Error fetching price:', error);
                toast.error('Failed to fetch price');
            }
        };

        if (fromCurrency && toCurrency) {
            fetchPrice();
        }
    }, [fromCurrency, toCurrency, amount, mintAddresses]);

    const handleSwap = () => {
        const temp = fromCurrency;
        setFromCurrency(toCurrency);
        setToCurrency(temp);
        setAmount(convertedAmount);
        setConvertedAmount(amount);
        toast.info('Currencies swapped');
    };

    const handleCompleteSwap = useCallback(async () => {
        try {
            if (!publicKey) {
                toast.warn('Connect your wallet first');
                return;
            }

            toast.info('Processing swap...');

            const amountValue = convertAmount(parseFloat(amount), fromCurrency);

            const response = await axios.post('http://localhost:5000/swap', {
                inputMint: mintAddresses[fromCurrency],
                outputMint: mintAddresses[toCurrency],
                amount: amountValue,
                slippageBps: slippageBps, // إرسال slippageBps إلى الطلب
            });

            if (!response.data || !response.data.swapTransaction) {
                throw new Error('Transaction data is incomplete');
            }

            const swapTransactionBuffer = Buffer.from(response.data.swapTransaction, 'base64');

            const transaction = VersionedTransaction.deserialize(swapTransactionBuffer);

            const signature = await sendTransaction(transaction, connection);

            const confirmation = await connection.confirmTransaction(
                {
                    signature,
                    commitment: 'confirmed',
                },
                60000
            );

            if (confirmation.value.err) {
                throw new Error(`Transaction failed: ${JSON.stringify(confirmation.value.err)}`);
            }

            toast.success('Transaction successful with signature: ' + signature);
        } catch (error) {
            console.error('Error during swap:', error);
            toast.error('Error during swap: ' + error.message);
        }
    }, [publicKey, fromCurrency, toCurrency, amount, sendTransaction, mintAddresses, convertAmount, slippageBps]);

    const handlePercentageClick = (percentage) => {
        setAmount((walletBalance * percentage).toFixed(2));
        toast.info(`Amount set to ${percentage * 100}% of wallet balance`);
    };

    const fetchTokenInfo = async (mint) => {
        try {
            const response = await axios.get(`https://api.coingecko.com/api/v3/coins/solana/contract/${mint}`);
            const tokenData = response.data;

            const tokenInfo = {
                symbol: tokenData.symbol.toUpperCase(),
                mint: mint,
                logoURI: tokenData.image.thumb,
            };

            return tokenInfo;
        } catch (error) {
            console.error('Error fetching token info from CoinGecko:', error);
            toast.error('Token information not found.');
            return null;
        }
    };

    const handleAddToken = async (mint) => {
        if (currencies.find(token => token.mint === mint)) {
            toast.info("Token already exists");
            return;
        }

        const tokenInfo = await fetchTokenInfo(mint);
        if (tokenInfo) {
            const updatedTokens = [...customTokens, { symbol: tokenInfo.symbol, mint: tokenInfo.mint, logoURI: tokenInfo.logoURI }];
            setCustomTokens(updatedTokens);

            localStorage.setItem('customTokens', JSON.stringify(updatedTokens));
            toast.success("Token added successfully");
        } else {
            toast.error("Token not found or not supported.");
        }
    };

    const currencyOptions = currencies.map((currency) => ({
        value: currency.symbol,
        label: currency.symbol,
        logo: currency.logoURI,
    }));

    const formatOptionLabel = ({ value, logo }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}> 
            <img src={logo} alt={value} className="currency-option-icon" />
            <span>{value}</span>
        </div>
    );

    return (
        <div className="swap-page">
            <ToastContainer />
            <div className="swap-container">
                <div className="wallet-info">
                    <p>Wallet Balance: {walletBalance} SOL</p>
                    <div className="percentage-buttons">
                        <button onClick={() => handlePercentageClick(0.5)}>50%</button>
                        <button onClick={() => handlePercentageClick(1)}>Max</button>
                    </div>
                </div>

                <div className="swap-section">
                    <div className="currency-amount-box">
                        <div className="currency-info">
                            <Select
                                classNamePrefix="currency-select"
                                options={currencyOptions}
                                value={currencyOptions.find(option => option.value === fromCurrency)}
                                onChange={(selectedOption) => setFromCurrency(selectedOption.value)}
                                formatOptionLabel={formatOptionLabel}
                            />
                        </div>
                        <input
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="0.00"
                            className="currency-amount"
                        />
                    </div>
                </div>

                <div className="swap-button-container">
                    <button className="swap-button" onClick={handleSwap}>
                        <img src="path-to-swap-icon.png" alt="swap" />
                    </button>
                </div>

                <div className="swap-section">
                    <div className="currency-amount-box">
                        <div className="currency-info">
                            <Select
                                classNamePrefix="currency-select"
                                options={currencyOptions}
                                value={currencyOptions.find(option => option.value === toCurrency)}
                                onChange={(selectedOption) => setToCurrency(selectedOption.value)}
                                formatOptionLabel={formatOptionLabel}
                            />
                        </div>
                        <input
                            type="number"
                            value={convertedAmount}
                            placeholder="0.00"
                            className="currency-amount"
                            readOnly
                        />
                    </div>
                </div>

                {price && (
                    <p className="price-info">
                        Price: 1 {fromCurrency} = {price.toFixed(8)} {toCurrency}
                    </p>
                )}

                <div className="complete-swap-button-container">
                    <button className="complete-swap-button" onClick={handleCompleteSwap}>
                        Complete Swap
                    </button>
                </div>

                <div className="add-token-section">
                    <h4>Add a Custom Token</h4>
                    <input type="text" placeholder="Mint Address" id="custom-mint" />
                    <button onClick={() => {
                        const mint = document.getElementById('custom-mint').value;
                        handleAddToken(mint);
                    }}>Add Token</button>
                </div>
            </div>
        </div>
    );
}

export default Swap;
